@import "colors-vars";

.messenger-icon:hover {
  color: $messenger-color !important;
}

.facebook-icon:hover {
  color: $facebook-color !important;
}

.twitter-icon:hover {
  color: $twitter-color !important;
}

.pinterest-icon:hover {
  color: $pinterest-color !important;
}

.heart-icon {
  @media (min-width: $break-md) {
    &:hover {
      color: $heart-color !important;
    }
  }

  &.added {
    color: $heart-color !important;
  }
}

.instagram-icon:hover {
  color: $instagram-color !important;
}

.youtube-icon:hover {
  color: $youtube-color !important;
}

.blogger-icon:hover {
  color: $blogger-color !important;
}

