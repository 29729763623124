.hover-zoom {
  &.hz-target, .hz-target {
    transition: transform .2s;
  }

  &:hover {
    &.hz-target, .hz-target {
      transform: scale(1.1);
    }
  }
}