.animation {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  z-index: -1;
}

@keyframes fadeInUpBig {
  from {
    margin-top: 10%;
  }

  to {
    margin-top: -3.5%;
  }
}

.animation__fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}