.splide__slide--has-video{cursor:pointer}.splide__slide--has-video:hover .splide__video__play{opacity:1}.splide__slide__container--has-video{cursor:pointer;position:relative}.splide__slide__container--has-video:hover .splide__video__play{opacity:1}.splide__video{height:100%;left:0;position:absolute;top:0;width:100%}.splide__video__wrapper{background:#000;height:inherit;width:inherit}.splide__video__wrapper div,.splide__video__wrapper iframe,.splide__video__wrapper video{height:100%;width:100%}.splide__video__play{align-items:center;background:#ccc;border:0;border-radius:50%;cursor:pointer;display:flex;height:40px;justify-content:center;left:50%;opacity:.7;position:absolute;top:50%;transform:translate(-50%,-50%);transition:opacity .1s linear;width:40px}.splide__video__play:after{border-color:transparent transparent transparent #000;border-style:solid;border-width:9px 0 9px 17px;content:"";display:inline-block;margin-left:4px}
#modal-pop-up-smarter-click h2,
#modal-pop-up-smarter-click h3 {
  font-size: 20px !important;
}

#modal-pop-up-smarter-click h1 {
  font-size: 35px !important;
}

#modal-pop-up-smarter-click .btn-info {
  background-color: #3697fb !important;
}

#modal-pop-up-smarter-click .btn-success {
  background-color: #83e24f !important;
  border-color: #83e24f !important;
}

#modal-pop-up-smarter-click .form-check,
#modal-pop-up-smarter-click .form-check * {
  cursor: pointer;
}

#modal-pop-up-smarter-click .error {
  border-color: #f44336 !important;
}

#modal-pop-up-country-select .modal-content {
  box-shadow: 0 0 10px #000;
  -webkit-box-shadow: 0 0 10px #000;
}

#modal-pop-up-country-select .modal-content .modal-body {
  padding: 15px 30px;
}

.modal :not(.modal-dialog-scrollable) .modal-content {
  height: 100%;
}

.modal :not(.modal-dialog-scrollable) .modal-content .modal-body {
  overflow-y: auto;
}

@media (min-width: 992px) {
  .modal .modal-lg-95 {
    max-width: 95%;
    height: 95%;
  }

  .modal .modal-lg-600 {
    max-width: 600px;
  }

  .modal .modal-lg-650 {
    max-width: 650px;
  }

  .modal .modal-lg-900 {
    max-width: 900px;
  }

  .modal .modal-lg-h-90 {
    height: 90%;
  }
}

.website-form input:not([type='submit']) {
  border: 1px solid #000 !important;
  border-radius: 0 !important;
  padding: 0 5px !important;
  height: 32px !important;
}

.website-form input:not([type='submit'])::placeholder {
  color: #BFBFBF !important;
}

.website-form input:not([type='submit']):focus {
  border: 1px solid #009587 !important;
  border-bottom: 3px solid #009587 !important;
  box-shadow: none !important;
}

.website-form .errors {
  background-color: #f44336 !important;
  color: rgba(255, 255, 255, 0.84) !important;
}

.website-form .success-messages {
  background-color: #4caf50 !important;
  color: rgba(255, 255, 255, 0.84) !important;
}

.or-wrap {
  text-align: center;
  position: relative;
}

.or-wrap .text {
  padding: 0 22px;
  position: relative;
  display: inline-block;
  background-color: #fff;
}

.or-wrap:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  border-top: 1px solid #E1E0E0;
  background: #E1E0E0;
  width: 100%;
  transform: translateY(-50%);
}

.alert.error {
  background-color: #f44336 !important;
  color: rgba(255, 255, 255, 0.84) !important;
}

.alert.error a {
  color: inherit;
}

.alert.success {
  background-color: #4caf50 !important;
  color: rgba(255, 255, 255, 0.84) !important;
}

.alert.success a {
  color: inherit;
}

#modal-cookies .modal-dialog {
  max-width: 600px;
}

#modal-cookies .modal-content {
  border-radius: 0;
}

#modal-cookies .ok-button {
  height: 58px !important;
}

@media (min-width: 992px) {
  #modal-cookies .ok-button {
    width: 400px !important;
    height: 44px !important;
  }
}

#modal-cookies .tvi-logo-mobile {
  font-size: 52px;
}

#modal-config-cookies .modal-content {
  max-height: 75%;
  border-radius: 10px;
}

#modal-config-cookies .modal-content .form-switch {
  padding-left: 60px;
  min-height: 30px;
}

#modal-config-cookies .modal-content .form-switch input {
  margin-left: -60px;
  width: 60px;
  height: 25px;
}

#modal-config-cookies .modal-content .form-switch label {
  opacity: 1;
}

.old-header #modal-config-cookies .modal-body {
  padding: 1rem;
}

#popup-newsletter .copied-voucher i {
  color: #4daf50 !important;
}

#popup-newsletter .copied-voucher i:before {
  content: "\F00C" !important;
}

.offcanvas .offcanvas-header h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

@media (min-width: 992px) {
  .offcanvas .offcanvas-header h3 {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .offcanvas.offcanvas-lg-end {
    top: 0;
    right: 0;
    left: auto;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-top: none;
    transform: translateX(100%);
    width: 400px;
    height: 100% !important;
  }

  .offcanvas.offcanvas-lg-end.show {
    transform: none;
  }
}

#cart-progress {
  position: relative;
}

#cart-progress .step-progress {
  position: absolute;
  height: 5px;
  background-color: #E1E0E0;
  z-index: -1;
  left: 50%;
  top: 15px;
  transform: translateX(-50%) !important;
}

#cart-progress .step-progress .bar {
  height: 100%;
  background-color: #D6236F;
}

#cart-progress .step {
  text-align: center;
  flex: 1 0 0%;
  display: flex;
  justify-content: center !important;
}

#cart-progress .step .step-number {
  width: 35px;
  line-height: 35px;
  display: block;
  margin: auto;
  background-color: #9D9D9D;
  border-radius: 50%;
  color: #FFF;
}

#cart-progress .step .step-name {
  font-weight: bold;
  color: #9D9D9D;
  word-wrap: break-word;
  word-break: break-word;
}

#cart-progress .step.active .step-number {
  background-color: #D6236F;
}

#cart-progress .step.active .step-name {
  color: #D6236F;
}

.review-stars {
  background-image: url(/build/images/stars.921f1706.png);
  background-repeat: no-repeat;
  width: 75px;
  height: 11px;
}

.review-stars.stars-empty {
  background-position: -2px -2px;
}

.review-stars.stars-filled {
  background-position: -2px -17px;
}

.review-stars-new {
  background-image: url(/build/images/stars.921f1706.png) !important;
}

.review-stars-gold {
  background-image: url(/build/images/stars-gold.c7bbe49d.png) !important;
}

.cart-item .cart-item-img {
  aspect-ratio: 1/1;
  text-align: center;
}

.cart-item .cart-item-img img {
  max-width: 100%;
  max-height: 100%;
}

.cart-item .cart-item-option {
  display: block;
  vertical-align: middle;
}

.cart-item .cart-item-option.type-color {
  float: left;
  margin: 3px 3px 0 0;
  width: 11px;
  height: 11px;
  border: 1px solid #000;
  border-radius: 50%;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.cart-item .cart-item-option.type-text {
  font-style: italic;
}

.cart-item .cart-item-quantity select {
  font-weight: bold;
}

.cart-item .cart-item-quantity select option {
  font-weight: bold;
}

.cart-item .cart-item-actions button {
  width: 40px;
  aspect-ratio: 1/1;
}

@media (min-width: 992px) {
  #cart {
    width: 550px !important;
  }
}

.search-form .suggestions {
  box-shadow: 5px 5px 10px -5px #000000;
}

@media (min-width: 992px) {
  .search-form .suggestions {
    margin-right: 0.75rem;
  }
}

.search-form .suggestions .suggestion {
  cursor: pointer;
}

.search-form .suggestions .suggestion .suggestion-name {
  font-weight: bold;
}

.search-form .suggestions .suggestion .suggestion-name span {
  font-weight: normal;
}

.search-form .suggestions .suggestion.saved-search .remove-saved-search {
  color: #adb5bd;
  vertical-align: top;
}

.search-form .suggestions .suggestion.saved-search .suggestion-name {
  color: #4555b7;
}

.search-form .suggestions .suggestion:hover {
  background-color: #EEEEEE;
}

.search-form .suggestions .suggestion .remove-saved-search {
  cursor: pointer;
}

.search-form .searcher .dropdown-content {
  outline: 1px solid #000000;
}

.search-form .searcher .dropdown-option:hover,
.search-form .searcher .dropdown-option.selected {
  background-color: #E7E7E7;
  font-weight: bold;
}

.search-form .searcher .btn-clear i {
  background-color: #cdcdcd;
  line-height: 25px;
  width: 25px;
}

#left-menu {
  width: 95%;
}

@media (min-width: 992px) {
  #left-menu {
    width: 420px;
  }
}

#left-menu a {
  color: #000000;
  text-decoration: none;
}

#left-menu #country-selector {
  max-height: 35%;
  overflow: auto;
}

#left-menu .hm-section {
  display: none;
}

#left-menu .hm-section.active {
  display: block;
}

#left-menu .hm-section .hm-back {
  cursor: pointer;
}

#left-menu .hm-section .hm-back i {
  padding-right: 6px;
}

#left-menu .hm-section .hm-navigate {
  cursor: pointer;
}

#left-menu .hm-section li {
  margin: 7px 0;
}

@media (min-width: 992px) {
  #left-menu .hm-section li {
    margin: 10px 0;
  }
}

#left-menu .hm-section li.i-1 {
  padding-left: 5px;
}

#left-menu .hm-section li.i-2 {
  padding-left: 10px;
}

#left-menu .hm-section li.i-3 {
  padding-left: 15px;
}

#left-menu .hm-section[data-type="main"] li {
  margin: 15px 0 !important;
}

#left-menu .hm-section[data-type="productType"] li {
  margin: 10px 0 !important;
}

.left-menu .highlight {
  background-color: #d8b545;
  text-transform: uppercase;
}

.left-menu .content {
  transition: transform 0.3s 0s;
  transform: translate3d(-100%, 0, 0);
}

.left-menu.visible {
  visibility: visible;
  transition: visibility 0s 0s;
}

.left-menu.visible .content {
  transform: translate3d(0, 0, 0);
}

.left-menu .countries-dropdown .close {
  -webkit-box-shadow: 0px -10px 40px rgba(255, 255, 255, 0.25);
  -moz-box-shadow: 0px -10px 40px rgba(255, 255, 255, 0.25);
  box-shadow: 0px -10px 40px rgba(255, 255, 255, 0.25);
}

.left-menu .bg-gray {
  background-color: #E4E4E4;
}

.left-menu .close-personalized-links i:before {
  content: "\F139";
}

.left-menu .open-personalized-links i:before {
  content: "\F13A";
}

#header #header-reviews .content,
header #header-reviews .content {
  width: 500px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2), 0 1px 6px rgba(0, 0, 0, 0.2);
}

#header #header-countries .content,
#header #header-languages .content,
header #header-countries .content,
header #header-languages .content {
  width: 200%;
  max-height: 350px;
}

#header #header-countries .content .element:hover,
#header #header-languages .content .element:hover,
header #header-countries .content .element:hover,
header #header-languages .content .element:hover {
  background-color: #5278ce !important;
}

#header #header-user .content,
#header .user .content,
header #header-user .content,
header .user .content {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2), 0 1px 6px rgba(0, 0, 0, 0.2);
}

#header #header-user.user-logged .content,
#header .user.user-logged .content,
header #header-user.user-logged .content,
header .user.user-logged .content {
  width: 250px;
}

#header #header-user.user-register .content,
#header .user.user-register .content,
header #header-user.user-register .content,
header .user.user-register .content {
  width: 600px;
}

#header .header-dropdown .content,
header .header-dropdown .content {
  cursor: auto !important;
}

#header #header-bottom .personalized-element,
header #header-bottom .personalized-element {
  background-color: #e4e4e4;
  border: 1px solid #c5c5c5;
}

header .search-form .suggestions,
.header-searcher .search-form .suggestions {
  padding-top: 40px;
  box-shadow: 0 9px 8px rgba(0, 0, 0, 0.15);
}

#loyalty-newsletter {
  transition: visibility 0.5s linear,opacity 0.5s linear;
}

.social-icon {
  background-image: url(/build/images/spritesheet.cfebfc45.png);
  background-repeat: no-repeat;
  display: block;
}

.social-icon.si-blog {
  background-position: -35px -32px;
  width: 30px;
  height: 30px;
}

.social-icon.si-facebook {
  background-position: -2px -3px;
  width: 30px;
  height: 30px;
}

.social-icon.si-instagram {
  background-position: -35px -1px;
  width: 30px;
  height: 30px;
}

.social-icon.si-pinterest {
  background-position: 0px -63px;
  width: 30px;
  height: 30px;
}

.social-icon.si-x {
  background-position: -108px -11px;
  width: 30px;
  height: 30px;
}

.social-icon.si-youtube {
  background-position: -66px -31px;
  width: 30px;
  height: 30px;
}

.social-icon.si-linkedin {
  background-position: -65px -2px;
  width: 30px;
  height: 30px;
}

.social-icon.si-tiktok {
  background-position: -30px -63px;
  width: 30px;
  height: 30px;
}

footer a {
  transition: all .2s;
}

footer a:hover {
  opacity: .7;
}

footer #footer-social .social-link {
  transition: transform .2s;
}

footer #footer-social .social-link:hover {
  transform: scale(1.5);
}

footer #footer-payment img {
  max-height: 30px;
}

#product-page .complement img,
#product-page .cross-selling img,
#modal-product .complement img,
#modal-product .cross-selling img {
  width: 70px;
}

@media (min-width: 576px) {
  #product-page .complement img,
  #product-page .cross-selling img,
  #modal-product .complement img,
  #modal-product .cross-selling img {
    width: 100px;
  }
}

#product-page .complement.has-image .name,
#product-page .cross-selling.has-image .name,
#modal-product .complement.has-image .name,
#modal-product .cross-selling.has-image .name {
  width: calc(100% - 75px);
}

@media (min-width: 576px) {
  #product-page .complement.has-image .name,
  #product-page .cross-selling.has-image .name,
  #modal-product .complement.has-image .name,
  #modal-product .cross-selling.has-image .name {
    width: calc(100% - 105px);
  }
}

.color-picker {
  position: relative;
  display: inline-block;
  margin: 0 4px;
}

.color-picker input {
  display: none;
}

.color-picker input:checked + label {
  border-width: 3px;
  color: #000;
}

.color-picker input:checked + label i {
  display: inline-block;
}

.color-picker input:disabled + label {
  display: none;
}

.color-picker label {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  border: 1px solid;
}

.color-picker label i {
  display: none;
}

.preview-color {
  display: inline-block;
  width: 14px;
  height: 14px;
  border: 1px solid;
  border-radius: 50%;
}

.hover-zoom.hz-target,
.hover-zoom .hz-target {
  transition: transform .2s;
}

.hover-zoom:hover.hz-target,
.hover-zoom:hover .hz-target {
  transform: scale(1.1);
}

.splide-pagination.pagination-line {
  padding: 10px 18px;
}

.splide-pagination.pagination-line li {
  flex: 1 1 auto;
}

.splide-pagination.pagination-line li .splide__pagination__page {
  width: 100%;
  border-radius: 0;
  background-color: #E6E6E6;
  margin: 0;
  height: 2px;
  display: flex;
}

.splide-pagination.pagination-line li .splide__pagination__page.is-active {
  background-color: #000000;
  opacity: 1;
  transform: none;
}

.splide__arrow.hide-disabled:disabled {
  display: none !important;
}

.splide.show-arrows-on-hover:hover .splide__arrows {
  display: block;
}

.splide.show-arrows-on-hover .splide__arrows {
  display: none;
}

@media (max-width: 575px) {
  .splide.show-arrows-on-hover .splide__arrows {
    display: none !important;
  }
}

.pagination .page-item:first-child .page-link {
  border-top-left-radius: 50rem !important;
  border-bottom-left-radius: 50rem !important;
}

.pagination .page-item:last-child .page-link {
  border-top-right-radius: 50rem !important;
  border-bottom-right-radius: 50rem !important;
}

.pagination .page-item:not(:first-child) .page-link {
  margin-left: -1.3px;
}

.pagination .page-item.active .page-link {
  color: #FFF;
  background-color: #000;
}

.pagination .page-item .page-link {
  color: #000;
  border-color: #000;
  padding: 0.5rem 0.8rem;
}

@media (min-width: 992px) {
  .pagination .page-item .page-link {
    padding: 0.75rem 1.2rem;
  }
}

.without-margin {
  --bs-gutter-x: 1.5rem;
  margin-right: calc(-.5* var(--bs-gutter-x));
  margin-left: calc(-.5* var(--bs-gutter-x));
}

.animation {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  z-index: -1;
}

@keyframes fadeInUpBig {
  from {
    margin-top: 10%;
  }

  to {
    margin-top: -3.5%;
  }
}

.animation__fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}

main.product-list .offcanvas {
  width: 100%;
}

main.product-list .offcanvas.offcanvas-bottom {
  height: 85vh;
}

@media (min-width: 992px) {
  main.product-list .offcanvas {
    width: 650px;
  }
}

main.product-list .offcanvas .offcanvas-header,
main.product-list .offcanvas .offcanvas-footer {
  padding: 20px 15px;
}

@media (min-width: 992px) {
  main.product-list .offcanvas .offcanvas-header,
  main.product-list .offcanvas .offcanvas-footer {
    padding: 35px 60px;
  }
}

main.product-list .offcanvas .offcanvas-body {
  padding: 20px 15px;
}

@media (min-width: 992px) {
  main.product-list .offcanvas .offcanvas-body {
    padding: 25px 60px;
  }
}

main.product-list .offcanvas section {
  padding: 20px 15px;
}

@media (min-width: 992px) {
  main.product-list .offcanvas section {
    padding: 25px 60px;
  }
}

.recommender .splide__arrow {
  padding: 13px 15px;
}

.recommender .title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.recommender .labels label {
  padding: 2px 4px;
  font-size: 8px;
}

@media (max-width: 320px) {
  .recommender .recommendation .rating {
    display: block !important;
  }

  .recommender .recommendation .rating .review-stars {
    margin: auto;
  }

  .recommender .recommendation .rating span {
    display: block;
    text-align: center;
    margin: 0 !important;
  }
}

.messenger-icon:hover {
  color: #0078ff !important;
}

.facebook-icon:hover {
  color: #1877F2 !important;
}

.twitter-icon:hover {
  color: #00aced !important;
}

.pinterest-icon:hover {
  color: #c92228 !important;
}

@media (min-width: 768px) {
  .heart-icon:hover {
    color: #F00 !important;
  }
}

.heart-icon.added {
  color: #F00 !important;
}

.instagram-icon:hover {
  color: #E1306C !important;
}

.youtube-icon:hover {
  color: #F00 !important;
}

.blogger-icon:hover {
  color: #FF5722 !important;
}

#filters-desktop {
  top: 150px;
}

@media (min-width: 992px) {
  #filters-desktop {
    top: 158px;
  }
}

#filters-desktop .filter-list {
  /*overflow-y: hidden;
    max-height: 60vh;*/
  /*&:hover {
      overflow-y: auto;
    }*/
}

#filters-desktop .filter-list .filter-type {
  padding: 10px 0;
}

.filter-list .box-collapse .body {
  /*max-height: 150px;

    @media (min-width: $break-lg) {
      max-height: 170px;
    }*/
}

.filter-list .box-collapse .body .filter:first-child {
  padding-top: 15px;
}

.filter-list .box-collapse .body .child-categories .filter:first-child {
  padding-top: 0;
}

.filter-list .box-collapse .body > *,
.filter-list .box-collapse .body .hidden-categories > *,
.filter-list .box-collapse .body .child-categories > * {
  padding-bottom: 15px;
}

.filter-list .box-collapse .body .filter input {
  border-width: 1px !important;
}

.filter-list .box-collapse .body .color-container {
  width: 70px;
}

@media (min-width: 992px) {
  .filter-list .box-collapse .body .color-container {
    width: 80px;
  }
}

.filter-list .box-collapse .body .color-container .color {
  width: 60px;
  height: 60px;
}

.filter-list .box-collapse .body .color-container .color i.selected:before {
  content: "\F00C";
}

#filters-mobile .filter-type {
  padding: 10px 1rem;
}

@media (min-width: 992px) {
  #filters-mobile .filter-type {
    padding: 25px 60px;
  }
}

@media (max-width: 992px) {
  #filters-mobile .offcanvas-footer {
    padding: 10px 15px;
  }
}

.newProducts label {
  font-size: 1rem;
}

.product-services-banner.icons-black .service-icon {
  background-image: url(/build/images/sprite.855d410a.png);
}

.product-services-banner.icons-white .service-icon {
  background-image: url(/build/images/sprite-white.87c145a9.png);
}

.product-services-banner .product-service .service-icon {
  background-repeat: no-repeat;
}

.product-services-banner .product-service .service-icon.service-icon_all {
  background-position: -3px -3px;
  width: 100px;
  height: 100px;
}

.product-services-banner .product-service .service-icon.service-icon_design {
  background-position: -109px -3px;
  width: 100px;
  height: 100px;
}

.product-services-banner .product-service .service-icon.service-icon_rug {
  background-position: -3px -109px;
  width: 100px;
  height: 100px;
}

.product-services-banner .product-service .service-icon.service-icon_sticker {
  background-position: -109px -109px;
  width: 100px;
  height: 100px;
}

.product-services-banner .product-service .service-icon.service-icon_txt {
  background-position: -215px -3px;
  width: 100px;
  height: 100px;
}

.no-results-banner li {
  list-style-position: inside;
}

.no-results-recommender .splide__slide {
  width: 36%;
  margin-right: 3%;
}

.no-results-recommender .splide__arrow:disabled {
  display: none;
}

@media (max-width: 1200px) {
  .personalized-products-recommender,
  .product-types-recommender {
    margin-right: -10px;
  }

  .personalized-products-recommender .splide__arrow.splide__arrow--next,
  .product-types-recommender .splide__arrow.splide__arrow--next {
    right: 7px !important;
  }
}

.tvi-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.tvi-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

.tvi-scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
}

textarea.disabled-resize {
  display: block;
  overflow: hidden;
  resize: none;
}

textarea.limit-rows {
  overflow-y: auto;
}

.overlay {
  background: #000;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
}

#last-viewed-products-container .splide-pagination {
  margin: 10px 10px 0 10px !important;
  padding: 0 0 25px 0;
}

#last-viewed-products-container .splide-pagination .splide__pagination {
  position: static;
}

#indexed-search .list {
  max-height: 450px;
}


