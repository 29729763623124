.no-results-banner {
  li {
    list-style-position: inside;
  }
}

.no-results-recommender {
  .splide__slide {
    width: 36%;
    margin-right: 3%;
  }

  .splide__arrow:disabled {
    display: none;
  }
}

@media (max-width: $break-xl) {
  .personalized-products-recommender, .product-types-recommender {
    margin-right: -10px;

    .splide__arrow.splide__arrow--next {
      right: 7px !important;
    }
  }
}