$paginator-active-background-color: #000000;
$paginator-background-color: #E6E6E6;

.splide-pagination.pagination-line {
  padding: 10px 18px;

  li {
    flex: 1 1 auto;

    .splide__pagination__page {
      width: 100%;
      border-radius: 0;
      background-color: $paginator-background-color;
      margin: 0;
      height: 2px;
      display: flex;

      &.is-active {
        background-color: $paginator-active-background-color;
        opacity: 1;
        transform: none;
      }
    }
  }
}

.splide__arrow.hide-disabled:disabled {
  display: none !important;
}

.splide.show-arrows-on-hover {
  &:hover {
    .splide__arrows {
      display: block;
    }
  }

  .splide__arrows {
    display: none;

    @media (max-width: $break-xs) {
      display: none !important;
    }
  }
}