.pagination {
  .page-item {
    &:first-child {
      .page-link {
        border-top-left-radius: 50rem!important;
        border-bottom-left-radius: 50rem!important;
      }
    }
    &:last-child {
      .page-link {
        border-top-right-radius: 50rem!important;
        border-bottom-right-radius: 50rem!important;
      }
    }
    &:not(:first-child) {
      .page-link {
        margin-left: -1.3px;
      }
    }
    &.active {
      .page-link {
        color: #FFF;
        background-color: #000;
      }
    }

    .page-link {
      color: #000;
      border-color: #000;
      padding: 0.5rem 0.8rem;

      @media (min-width: $break-lg) {
        padding: 0.75rem 1.2rem;
      }
    }
  }
}

.without-margin {
  --bs-gutter-x: 1.5rem;
  margin-right: calc(-.5* var(--bs-gutter-x));
  margin-left: calc(-.5* var(--bs-gutter-x));
}