@import "../Header/vars";

#filters-desktop {
  top: $header-height-xs;
  @media (min-width: $break-lg) {
    top: $header-height-lg;
  }

  .filter-list {
    /*overflow-y: hidden;
    max-height: 60vh;*/

    .filter-type {
      padding: 10px 0;
    }

    /*&:hover {
      overflow-y: auto;
    }*/
  }
}

.filter-list{
  .box-collapse .body {
    /*max-height: 150px;

    @media (min-width: $break-lg) {
      max-height: 170px;
    }*/

    .filter:first-child {
      padding-top: 15px;
    }

    .child-categories .filter:first-child{
      padding-top: 0;
    }

    & > *, .hidden-categories > *, .child-categories > * {
      padding-bottom: 15px;
    }

    .filter input{
      border-width: 1px !important;
    }

    .color-container {
      width: 70px;

      @media (min-width: $break-lg) {
        width: 80px;
      }

      .color {
        width: 60px;
        height: 60px;

        i.selected:before {
          content: "\F00C";
        }
      }
    }

  }
}

#filters-mobile {
  .filter-type {
    padding: 10px 1rem;
    @media (min-width: $break-lg) {
      padding: 25px 60px;
    }
  }

  .offcanvas-footer {
    @media (max-width: $break-lg) {
      padding: 10px 15px;
    }
  }
}

.newProducts {
  label {
    font-size: 1rem;
  }
}