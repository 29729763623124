@mixin get-service-icon($x, $y, $width, $height) {
  background-position: $x $y;
  width: $width;
  height: $height;
}

.product-services-banner {
  &.icons-black {
    .service-icon {
      background-image: url(../../../images/website/personalizedServices/banner/sprite.png);
    }
  }
  &.icons-white {
    .service-icon {
      background-image: url(../../../images/website/personalizedServices/banner/sprite-white.png);
    }
  }
  .product-service {
    .service-icon {
      background-repeat: no-repeat;

      &.service-icon_all {
        @include get-service-icon(-3px, -3px, 100px, 100px);
      }

      &.service-icon_design {
        @include get-service-icon(-109px, -3px, 100px, 100px);
      }

      &.service-icon_rug {
        @include get-service-icon(-3px, -109px, 100px, 100px);
      }

      &.service-icon_sticker {
        @include get-service-icon(-109px, -109px, 100px, 100px);
      }

      &.service-icon_txt {
        @include get-service-icon(-215px, -3px, 100px, 100px);
      }
    }
  }
}