main.product-list {
  .offcanvas {
    width: 100%;

    &.offcanvas-bottom {
      height: 85vh;
    }

    @media (min-width: $break-lg) {
      width: 650px;
    }

    .offcanvas-header, .offcanvas-footer {
      padding: 20px 15px;

      @media (min-width: $break-lg) {
        padding: 35px 60px;
      }
    }

    .offcanvas-body {
      padding: 20px 15px;

      @media (min-width: $break-lg) {
        padding: 25px 60px;
      }
    }

    section {
      padding: 20px 15px;
      @media (min-width: $break-lg) {
        padding: 25px 60px;
      }
    }
  }
}